import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import './header.scss'
import {Link} from "react-router-dom";
import linkLogo from "../../media/lushlink-2022-solid.svg";
import React from "react";

export default function Header() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Link to="/" className="navbar-brand">
          <img alt="LushLink Logo" src={linkLogo} className="logo"/>
          Lush<span className="accent-font font-weight-800">Link</span>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="">
            {/*<Link to="solutions" className="nav-link">Solutions</Link>*/}
            {/*<Link to="about" className="nav-link">About</Link>*/}
            {/*<NavDropdown title="Resources" id="basic-nav-dropdown">*/}
            {/*  <Link to="about" className="dropdown-item">Blog</Link>*/}
            {/*</NavDropdown>*/}
            {/*<a className="btn btn-outline-info" href="/user/login" style={{margin:"0 1rem"}}>Login</a>*/}
            {/*<Link to="signup" className="btn btn-outline-light">Signup</Link>*/}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
