import React from "react";
import {Row} from "react-bootstrap";

export default function Collections() {



  return (
    <>
      <Row>
        <div className="page-banner d-flex justify-content-center align-items-center" style={{background: "deeppink", minHeight: "150px"}}>
          <h1>Art Collections</h1>
        </div>
      </Row>

      <Row>
        <div className="search-form d-flex justify-content-center align-items-center" style={{minHeight: "150px"}}>

        </div>
      </Row>
    </>
  )
}
