import React from "react";

import styles from "./hero.module.scss"
import linkLogo from "../../media/lushlink-2022-solid.svg"
import heroVideoBackground from "../../media/lushlink-hero.mp4"

export default function Hero() {
  return (
    <div className={styles.hero}>
      <div className={styles.content}>
        <div className={styles.title}>
          <h1 className={styles.brand}>
            Connecting artists<br/>
            with their<br/>
            (<span style={{color:"black"}}>web3</span>) communities.
          </h1>
          <img alt="LushLink Logo" src={linkLogo} className={styles.logo}/>
        </div>
      </div>
      <video autoPlay={true} muted loop className={styles.video}>
        <source src={heroVideoBackground} type="video/mp4"/>
      </video>
    </div>
  )
}
