import React from "react";

import styles from "./hero-signup.module.scss"

import heroVideoBackground from "../../media/lushlink-hero.mp4"
import SignupForm from "../form/signup";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

export default function HeroSignup() {
  return (
    <div className={styles.hero}>
      <div className={styles.content}>
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col xs={12} md={6} lg={7}>
              <h1 className={styles.brand}>
                See what LushLink can do for you.
              </h1>
              <p style={{fontWeight: "bold", fontSize: "1.75rem"}}>Let us show you how Lushlink can help you with
                <ul>
                  <li>AI Generated Art</li>
                  <li>PFP generator</li>
                  <li>Block Chaim based CRM</li>
                  <li>Web3 Games</li>
                  <li>Smart Contract Management</li>
                </ul>
              </p>
            </Col>
            <Col xs={12} md={6} lg={5}>
              <SignupForm/>
            </Col>
          </Row>
        </Container>
      </div>
      <video autoPlay={true} playsInline muted loop className={styles.video}>
        <source src={heroVideoBackground} type="video/mp4"/>
      </video>
    </div>
  )
}
