import "./content-section.scss"
import Container from "react-bootstrap/Container";

export default function ContentSection(props: any): JSX.Element {
  return (
    <Container fluid={true} className={`py-5 display--${props.display}`}>
      <Container>
        {props.children}
      </Container>
    </Container>

  )
}
