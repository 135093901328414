import Header from "../components/global/header"
import Container from "react-bootstrap/Container"
import React from "react"
import {Outlet} from "react-router-dom"
import Footer from "../components/global/footer";

export default function FullWidth(): JSX.Element {
  return (
    <Container fluid={true} className="p-0">
      <Header />
      <Outlet />
      <Footer />
    </Container>
  )
}
