import React from "react"

import styles from "./video-container.module.scss"

export default function VideoContainer(props: any): JSX.Element {
  return (
    <div className={styles.videoContainer}>
      <video autoPlay={true} muted loop className={styles.video}>
        {/*<source src="/themes/custom/lushlink/video-background.mp4" type="video/mp4"/>*/}
        <source src={props.url} type="video/mp4"/>
      </video>
    </div>
  )
}
