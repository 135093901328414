import React from "react";

import styles from "./hero-signup.module.scss"

import heroVideoBackground from "../../media/lushlink-hero.mp4"
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

export default function HeroInvitationReceived() {

  // @TODO - LOOK FOR TIMESTAMP IN URL

  return (
    <div className={styles.hero}>
      <div className={styles.content}>
        <Container>
          <Row className="justify-content-between align-items-center">
            <Col xs={12} md={6} lg={7}>
              <p style={{fontWeight: "bold", fontSize: "1.75rem"}}></p>
            </Col>
            <Col xs={12} md={6} lg={5}>
              <div className="signupForm">
                <h1>Request Received!</h1>
                <p>
                  We appreciate your interest! You are on the list and a LushLink representative will
                  be in contact with you soon. We look forward to working with you.
                  <br/>
                  <br/>
                  - Your LushLink Team
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <video autoPlay={true} muted loop className={styles.video}>
        <source src={heroVideoBackground} type="video/mp4"/>
      </video>
    </div>
  )
}
