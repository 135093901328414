import Container from "react-bootstrap/Container"
import {Col, Row} from "react-bootstrap"

import './footer.scss'
import linkLogo from "../../media/lushlink-2022-solid.svg";
import React from "react";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <Container>
          <Row>
            <Col>
              <h1 className="d-flex flex-column align-items-center justify-content-center">
                <img alt="LushLink Logo" src={linkLogo} className="logo"/>
                <div>Lush<span className="accent-font font-weight-800">Link</span></div>
                <div><span style={{fontSize: "1rem", marginLeft: "1rem"}}>Connecting artists with their web3 communities.</span></div>
              </h1>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="credit">
        <Container>
          <Row className="text-center justify-content-center align-items-center">
            <Col>
              <p className="m-0">LushLink&reg; is designed, developed and maintained by the 8bit Homies, Inc. {new Date().getFullYear()}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>

  )
}
