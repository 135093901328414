import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

// import logo from './logo.svg'
import './bootstrap-wrapper.scss'
import './App.scss'


import Homepage from "./pages/home";
import Solutions from "./pages/solutions";
import About from "./pages/about";
import FullWidth from "./layouts/full-width";
import SignupPage from "./pages/signup";
import InvitationReceivedPage from "./pages/invitation-received";
import Collections from "./pages/collections";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FullWidth />}>
          <Route index element={<Homepage />} />
          <Route path="collections" element={<Collections />} />
          <Route path="about" element={<About />} />
          <Route path="solutions" element={<Solutions />} />
          <Route path="signup" element={<SignupPage />} />
          <Route path="invitation-received" element={<InvitationReceivedPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
