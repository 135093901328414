import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import HCaptcha from '@hcaptcha/react-hcaptcha';

import "./signup.scss"
import React, {useState} from "react";
import axios, {AxiosResponse} from "axios";
import {useNavigate} from "react-router-dom";

export default function SignupForm(): JSX.Element {

  const [formState, setFormState] = useState({
    email: "",
    twitter: "",
    reason: {artEngine:false, smartContracts: false, crm: false, gameSystem: false},
    processing: false,
    errorMessage: ""
  })

  const [isHuman, setisHuman] = useState(false)
  const [validated, setValidated] = useState(false);

  // Router.
  const navigate = useNavigate()

  const updateFormState = (event: any) => {
    if (event.target.name === 'email') {
      formState.email = event.target.value
      setFormState(formState)
    }
    if(event.target.name === 'twitter') {
      formState.twitter = event.target.value
      setFormState(formState)
    }
    if (event.target.name === 'artEngine') {
      formState.reason.artEngine = event.target.checked
      setFormState(formState)
    }
    if (event.target.name === 'smartContracts') {
      formState.reason.smartContracts = event.target.checked
      setFormState(formState)
    }
    if (event.target.name === 'crm') {
      formState.reason.crm = event.target.checked
      setFormState(formState)
    }
    if (event.target.name === 'gameSystem') {
      formState.reason.gameSystem = event.target.checked
      setFormState(formState)
    }
  }

  const onVerifyCaptcha = async (value: any) => {
    setisHuman(true)
  }

  // Handles the submit event on form submit.
  const handleRegisterSubmit = async (event: any) => {
    // Stop the form from submitting and refreshing the page.
    event.preventDefault()

    const form = event.currentTarget

    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() === true) {
      formState.processing = true
      setFormState(formState)

      const data = formState
      const rand = 1 + Math.random() * (1000 - 1)

      axios
        .post('/api/crm/leads/request-invitation?' + rand, data)
        .then((res: AxiosResponse<any>) => {
          console.log(res)
          const timestamp = Math.floor(Date.now() / 1000)
          if (res.data && res.data.success) {
            navigate('/invitation-received?time=' + timestamp)
          }
          if (res.data && !res.data.success) {
            formState.processing = false;
            formState.errorMessage = res.data.message
            setFormState(formState)
          }
        })
        .catch((error) => {
          console.error('ERROR', error)
          navigate('/')
        })
    }

  }

  return (
    <Form noValidate validated={validated} className="signupForm" onSubmit={handleRegisterSubmit}>
      <h1>Schedule a demo</h1>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control required placeholder="Email Address" name="email" onChange={updateFormState} />
        <Form.Control.Feedback>Love your email address!</Form.Control.Feedback>
        <Form.Control.Feedback type="invalid">Sorry, we need that email address.</Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label>Twitter Handle</Form.Label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">@</div>
          </div>
          <Form.Control placeholder="Twitter handle" name="twitter" onChange={updateFormState} />
        </div>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Reason for interest</Form.Label>
        <Form.Check type="checkbox" name="artEngine" value="artEngine" label="AI Generated Art" onChange={updateFormState}/>
        <Form.Check type="checkbox" name="artEngine" value="pfp" label="PFP generator" onChange={updateFormState}/>
        <Form.Check type="checkbox" name="smartContracts" value="smartContracts" label="Smart Contract Management" onChange={updateFormState}/>
        <Form.Check type="checkbox" name="crm" value="crm" label="Block Chaim based CRM" onChange={updateFormState}/>
        <Form.Check type="checkbox" name="gameSystem" value="gameSystem" label="Web3 Games" onChange={updateFormState}/>
      </Form.Group>

      {formState.processing
        ? <Button variant="light" disabled type="submit">Processing...</Button>
        :
        <>
          <HCaptcha sitekey="34d35405-e2ee-4245-b0fd-e88045e2e687" onVerify={onVerifyCaptcha}/>
          {isHuman
            ? <Button  variant="primary" type="submit">Submit</Button>
            : <Button  disabled variant="primary" type="submit">Submit</Button>
          }
        </>
      }
    </Form>
  )
}
