import React from "react"
import { Parallax } from 'react-parallax'

import {Card, Col, Row} from "react-bootstrap"
import Container from "react-bootstrap/Container"

import Hero from "../components/hero/hero"
import ContentSection from "../components/layout/content-section"
import VideoContainer from "../components/video/video-container"
import BelowNavBanner from "../components/banner/below-nav"

import lushLinkGenerate from "../media/lushlink-generate.mp4"
import lushLinkAnalytics from "../media/lushlink-analytics.png"
import carkayousBackground from "../media/lushlink-carkayous.png"
import lushLinkGenerator from "../media/grip-solid.svg"
import lushLinkSmartContract from "../media/passport-solid.svg"
import lushLinkCRM from "../media/user-group-solid.svg"
import lushLinkGameEngine from "../media/dice-d20-solid.svg"

import "./home.scss"
import {Link} from "react-router-dom";

export default function Homepage(): JSX.Element {
  return (
    <>
      <BelowNavBanner />
      <Hero/>

      <Container className="py-5" id="what-lushlink-can-do">
        <Row className="justify-content-center mb-5">
          <Col sm={10} className="text-center">
            <h2>Learn what LushLink can do for you.</h2>
          </Col>
        </Row>

        <Row xs={1} sm={1} lg={4} className="g-4">
          <Card bg={"dark"} text={'white'}>
            <Card.Body>
              <Card.Img variant="top" src={lushLinkGenerator}/>
              <Card.Title>PFP Generator Tool</Card.Title>
              <Card.Text>
                With LushLinks generative art engine building your NFT PFP and editions based collections is a breeze. Simply zip up your layers, upload them and click a button to generate a unique and rarity controlled collection.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card bg={"dark"} text={'white'}>
            <Card.Img variant="top" src={lushLinkSmartContract} style={{maxHeight:"197px"}}/>
            <Card.Body>
              <Card.Title>Smart Contracts</Card.Title>
              <Card.Text>
                We’ve made, and learned from, all the mistakes so that you don’t have to. Our experts will guide you through the creation of your NFT's smart contract every step of the way.
              </Card.Text>
            </Card.Body>
          </Card>

          <Card bg={"dark"} text={'white'}>
            <Card.Img variant="top" src={lushLinkCRM} style={{maxHeight:"197px"}}/>
            <Card.Body>

              <Card.Title>Blockchain Based CRM</Card.Title>
              <Card.Text>
                Digital wallets are the future of identity, authentication and commerce. LushLink can advance your digital experience into the future with wallet integrations and a powerful block chain based CRM so that you can collect, nurture and convert contacts into an engaged community.
              </Card.Text>
            </Card.Body>
          </Card>


          <Card bg={"dark"} text={'white'}>
            <Card.Img variant="top" src={lushLinkGameEngine} style={{maxHeight:"197px"}}/>
            <Card.Body>
              <Card.Title>Blockchain-based Game System</Card.Title>
              <Card.Text>
                Combining NFT digital art collectibles, smart contracts and digital wallets provide the building blocks to produce games to engage your communities. Leverage your art in an all new way with LushLinks gamification tools.
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>

      <ContentSection display="light">
        <Row>
          <Col md={7}>
            <VideoContainer url={lushLinkGenerate}/>
          </Col>
          <Col className="pt-5">
            <div style={{fontSize:"1rem"}}>Empowered by</div>
            <h2 style={{fontSize:"5rem", fontWeight: "800"}}>Flexibility</h2>
            <p style={{fontSize:"1.5rem"}}>LushLink allows you to quickly load your art and generate endless permutations with a single click, so you can focus on whats most important... your creative process</p>
          </Col>
        </Row>
      </ContentSection>
      <ContentSection display="dark" reverse={true}>
        <Row className="my-5 align-items-center d-flex flex-row-reverse">
          <Col md={6}>
            <img className="w-100" src={lushLinkAnalytics} alt="LushLink Analytics"/>
          </Col>
          <Col className="pt-6">
            <div style={{fontSize:"1rem"}}>Supported by</div>
            <h2 style={{fontSize:"5rem", fontWeight: "800"}}>Analytics</h2>
            <p style={{fontSize:"1.5rem"}}>LushLink has in-house-built analytics to understand exactly what you have created,
              what percentage of your collection contains certain traits and rarity score of each NFT. Additionally, our
              logic ensures that you never have to worry about a duplicate.</p>
          </Col>
        </Row>
      </ContentSection>


      <Parallax blur={2} bgImage={carkayousBackground} bgImageAlt="Carkayous Feral Fish" strength={200}>
        <div className="content d-flex flex-column justify-content-center align-items-center p-5" style={{zIndex: "2"}}>
          <p className="mb-0 accent-font font-weight-800">Client Spotlight</p>
          <h1 style={{fontSize:"6rem", fontWeight: "800", textAlign: "center", marginTop: "0"}}>The Invasion of<br/> the Feral Fish</h1>
          <h2>CARKAYOUS by Jesse Smith</h2>
          <p><a className="btn btn-primary" href="https://app.ebisusbay.com/collection/carkayous-feral-fish" target="_blank" rel="noreferrer">Check it out</a> </p>
        </div>
      </Parallax>


      <Container fluid={true} style={{background: "deeppink", textAlign: "center", padding: "2rem"}}>
        <Row className="justify-content-center">
          <Col md={7}>
            <h1 style={{fontSize: "4rem", fontWeight: "800"}}>READY TO LEAN MORE?</h1>
            <h3>We are currently accepting new clients.</h3>
            <Row className="mt-5 justify-content-center">
              <Col sm={4}>
                <Link className="btn btn-large btn-primary" to="signup">Schedule a demo</Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  )
}
