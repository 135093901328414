import {Col, Row} from "react-bootstrap";
import Container from "react-bootstrap/Container";

import './below-nav.scss'
import {Link} from "react-router-dom";

export default function BelowNavBanner(): JSX.Element {
  return (
    <Container fluid={true} className="banner--below-nav">
      <Row className="justify-content-center align-items-center">
        <Col sm={6}>
          <p className="m-0 text-center">See what LushLink can do for you <Link to="signup" className="btn btn-sm btn-outline-light">Schedule a demo</Link></p>
        </Col>
      </Row>
    </Container>
  )
}
